import React from 'react'
import PropTypes from 'prop-types'
import logo from '../images/IMG-0049.png'


const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-scissors"></span>
        </div>
        <div className="content">
            <div className="inner">
                <img height="200" style={{ width: '100%', maxWidth: '449px' }} src={logo} alt={"Chez Suavé Logo"} />
                <p>Ce n'est pas juste une coupe, c'est une expérience.</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('hours')}}>Heures d'ouverture</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('appointments')}}>Prendre un rendez-vous</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('address')}}>Adresse</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
