import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="hours" className={`${this.props.article === 'hours' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Heures d'ouverture</h2>
          <table>
            <tr><th style={{ textAlign: 'right' }}>Lundi</th><td>10h - 18h</td></tr>
            <tr><th style={{ textAlign: 'right' }}>Mardi</th><td>10h - 18h</td></tr>
            <tr><th style={{ textAlign: 'right' }}>Mercredi</th><td>10h - 19h</td></tr>
            <tr><th style={{ textAlign: 'right' }}>Jeudi</th><td>9h - 21h</td></tr>
            <tr><th style={{ textAlign: 'right' }}>Vendredi</th><td>9h - 21h</td></tr>
            <tr><th style={{ textAlign: 'right' }}>Samedi</th><td>10h - 17h</td></tr>
            <tr><th style={{ textAlign: 'right' }}>Dimanche</th><td>Fermé</td></tr>
          </table>
          <ul className="actions" style={{ textAlign: 'center'}}>
            <li><a style={{ textDecoration: 'none', borderBottom: 0}} href={"javascript:;"} onClick={() => { this.props.onCloseArticle(); setTimeout(() => this.props.onOpenArticle('appointments'), 400); }}><input type="reset" value="Prendre un rendez-vous" /></a></li>
          </ul>
          <div></div>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'appointments' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h3 className="major">Prendre un rendez-vous</h3>
          <iframe src="https://chezsuavbarbershop.resurva.com/book?embedded=true" name="resurva-frame" frameBorder="0"
                  height="450" style={{ 'width':'100%' }}></iframe>
          {close}
        </article>

        <article id="address" className={`${this.props.article === 'address' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Adresse</h2>
          <span className="image main">
            <iframe height="320" frameBorder="0" style={{ border: 0, width: '100%' }} src="https://www.google.com/maps/embed/v1/place?q=1356%20Boulevard%20Cur%C3%A9-Labelle%2C%20Blainville%2C%20QC%2C%20Canada&key=AIzaSyA6Bu70wTVeFY3gh8wQLQm6QA_isyU33Qg"
                    allowFullScreen/>
          </span>
          <div>1356 Boul. Curé-Labelle, Suite 244, Blainville, QC, Canada, J7C 2P2<br/>Tél : (438) 884-8059</div>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <div>Par teléphone: (438) 884-8059</div>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Nom</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Envoyer" className="special" /></li>
              <li><input type="reset" value="Recommencer" /></li>
            </ul>
          </form>
          <ul className="icons">
            {/*<li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li>*/}
            <li><a href="https://www.instagram.com/chezsuave/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
